import React, { useState, useEffect, memo , useRef } from "react";
import "./ResponseDetails.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  Space,
  Row,
  Col,
  Table,
  Tag,
  Tooltip,
  Progress,
  Badge,
  Spin,
  Checkbox,
  Button,
  Dropdown,
  List,
  Input
} from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CloseOutlined, LoadingOutlined , SearchOutlined } from "@ant-design/icons";
import AddToFindingsIcon from "../../assets/icons/add-to-findings-new.svg";
import AddToFindingsDisabledIcon from "../../assets/icons/add-to-findings-disabled.svg";
import {
  getResponseDetails,
  getResponseReasons,
  getResponseReasonsDropdown,
} from "../../services/ResponsesAPI";
import {
  LIKERT_SCALE_CHART_OPTIONS,
  RANK_ORDER_CHART_OPTIONS,
  GRID_TYPE_CHART_OPTIONS,
} from "../../shared/Charts/ResponsesCharts";
import {
  LIKERT_CHART,
  RANK_ORDER,
  SINGLE_SELECT_A,
  SINGLE_SELECT_B,
  MULTIPLE_SELECT,
  OPEN_RESPONSE,
  GRID_TYPE,
  MAX_DETAILS,
  QUESTION_ID,
} from "../../constants/ResponseDetailConstant";
import {
  COLOR_PALETTE_THREE_RANK,
  COLOR_PALETTE_FOUR_RANK,
  COLOR_PALETTE_FIVE_RANK,
  COLOR_PALETTE_SIX_RANK,
  COLOR_PALETTE_SEVEN_RANK,
  LIKERT_COLOR_PALETTE,
  LIKERT_COLOR_PALETTE_WITH_FIVE_COLORS,
  LIKERT_COLOR_PALETTE_WITH_SIX_COLORS,
  LIKERT_COLOR_PALETTE_WITH_EIGHT_COLORS,
  LIKERT_COLOR_PALETTE_WITH_NINE_COLORS,
  GRID_TYPE_COLOR_PALETTE_THREE,
  GRID_TYPE_COLOR_PALETTE_FOUR,
  GRID_TYPE_COLOR_PALETTE_SEVEN,
  GRID_TYPE_COLOR_PALETTE_FIVE,
  GRID_TYPE_COLOR_PALETTE_SIX,
} from "../../constants/RankOrderColorPalet";
import {
  setFindingsReportAddSupportingData,
  setNotificationData,
  setReasonsTableParams,
} from "../../reducer/PEBCommonSlice";
import { saveFindingsReportData } from "../../services/ExperienceBankAPI";
import FindingReportSettingsModal from "../finding-report-setting-modal/FindingReportSettingsModal";
import { NotificationTypes } from "../../shared/Notification/Notification";
import { useCallback } from "react";
const CheckboxGroup = Checkbox.Group;

const ResponseDetails = memo((props) => {  
  const setAddAllToReportLoading = props.setAddAllToReportLoading;
  const addAllCards= props.addAllCards || false;
  const setCallResponseDetails = props.setCallResponseDetails;
  let allCardsData = [];
  const tableParams = useSelector((state) => state.PEBCommon.tableParam);
  const isOpen = useSelector((state) => state.PEBCommon?.findingsReport.isOpen);
  const edit_access_flag = useSelector(
    (state) => state.PEBCommon?.hasAccessToReport
  );
  const disableData = useSelector((state) => state.PEBCommon?.disableData);
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail); // get User details
  const userId = userInfo.user_id;
  const filterAppliedData = useSelector(
    (state) => state.PEBCommon?.exploreFilterApplyData
  );
  const deleteCardRefresh = useSelector(
    (state) => state.PEBCommon?.deleteCardRefresh
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(props.visible);
  const responseRowRecord = props.responseRowRecord;
  const categoryName = props.categoryName;
  const comparatorData = props.comparatorData;
  const [responseType, setResponseType] = useState(
    responseRowRecord.responseType
  );
  const updatedResponseType = useRef(responseRowRecord.responseType);
  const [questionId, setQuestionId] = useState(responseRowRecord.id);
  const [agreeDisagreeDataSource, setAgreeDisagreeDataSource] = useState([]);
  const [likertScaleDataSource, setLikertScaleDataSource] = useState([]);
  const [likertsLegends, setLikertsLegends] = useState([]);
  const [legendHeads, setLegendHeads] = useState();
  const [multiSelectOpenTextData, setMultiSelectOpenTextData] = useState([]);
  const [rankOrderDataSource, setRankOrderDataSource] = useState([]);
  const [gridTypeDataSource, setGridTypeDataSource] = useState([]);
  const [reasonDataSource, setReasonDataSource] = useState([]);
  const [reasonColumns, setReasonColumns] = useState([]);
  const [preferenceDropdownList, setPreferenceDropdownList] = useState();
  const [reasonDropdownList, setReasonDropdownList] = useState();
  const [respondentsDropdownList, setRespondentsDropdownList] = useState();
  const [combinedIndicationList ,setCombinedIndicationList] = useState();
  const [viewMoreOpenResponses, setViewMoreOpenResponses] = useState({});
  const [maxItemsToShow, setMaxItemsToShow] = useState({});
  const [currentRespondents, setCurrentRespondents] = useState(null);
  const [responseDetailsLength, setResponseDetailsLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const defaultCheckedList = [];
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const checkAll = reasonDropdownList?.length === checkedList?.length;
  const indeterminate =
    checkedList?.length > 0 && checkedList?.length < reasonDropdownList?.length;
  const analysisData = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisData
  );
  const [reasonVisibleFlag, setReasonVisibleFlag] = useState();
  const [expandedRows, setExpandedRows] = useState({});
  const [reasonFilterAppliedData , setReasonFilterAppliedData] = useState();
  const [appliedReasonValues, setAppliedReasonValues] = useState([]);
  let isReset = useRef(false);
  let selectedRef = useRef([]);

  const handleAllCards=(index=-1)=>{
    let dataSource = [];
    if(responseType===SINGLE_SELECT_A ){
      dataSource = agreeDisagreeDataSource
    }
    else if(responseType===LIKERT_CHART){
      dataSource = likertScaleDataSource
    }
    else if(responseType===RANK_ORDER){
      dataSource = rankOrderDataSource;
    }
    else if(responseType===GRID_TYPE){
      dataSource = gridTypeDataSource;
    }
    else if(responseType === MULTIPLE_SELECT ||
      responseType === SINGLE_SELECT_B){
      dataSource = multiSelectOpenTextData;
    }
    else if(responseType === RANK_ORDER){
      dataSource = rankOrderDataSource;
    }
    
    let dataArray = [];
    if (dataSource.length > 0) dataArray = dataArray.concat(dataSource);
    if (allCardsData.length > 0) dataArray = dataArray.concat(allCardsData);
    
    if(index!=-1){
      dataArray = dataArray.slice(index, index + 1);
    }
    
    let dataArraySize = dataArray.length;

    for(let i=0;i<dataArraySize;i++){
    
      setTimeout(() => {
        
        addToFindingsClick(dataArray[i] , false, i);
        if(i==dataArraySize-1 && index==-1){
          setAddAllToReportLoading(false);
        }
        
      }, 2000*i);
      
    }
  }

  useEffect(()=>{
    if(addAllCards){
      setCallResponseDetails(false);
      setTimeout(() => {
        handleAllCards();        
      }, 5000);
    }
  },[props])
  
  //1st view Table Likert Chart Columns
  const likertScaleColumns = [
    {
      title: "Options",
      dataIndex: "respondents",
      key: "respondents",
      width: "30%",
      render: (_, { respondents }) => (
        <Tag
          color={"blue"}
          key={respondents}
          style={{
            width: respondents.length > 18 && "230px",
            hyphens: "auto",
            whiteSpace: respondents.length > 25 && "normal",
          }}>
          {respondents}
        </Tag>
      ),
    },
    {
      title: (
        <>
          <div style={{ marginBottom: 24 }}>Responses</div>
          <div class="horizontal-line">
            <div class="break">
              <span class="percentage">0</span>
            </div>
            <div class="break">
              <span class="percentage">25%</span>
            </div>
            <div class="break">
              <span class="percentage">50%</span>
            </div>
            <div class="break">
              <span class="percentage">75%</span>
            </div>
            <div class="break">
              <span class="percentage">100%</span>
            </div>
          </div>
        </>
      ),
      dataIndex: "responses",
      key: "responses",
      width: "60%",
      render: (_, record) => (
        <HighchartsReact highcharts={Highcharts} options={record.responses} />
      ),
    },
    {
      title: "Build/Update Report",
      dataIndex: "add",
      key: "add",
      width: "10%",
      render: (_, record, index) => (
        <Space size="middle">
          {!record.addToFindingsFlag ? (
            <Space>
              <img
                alt={"Build/Update Report"}
                src={AddToFindingsDisabledIcon}
              />
              <span style={{ color: "#B2B0B6" }}> Add to Report</span>
            </Space>
          ) : (
            <Space>
              <img
                alt={"Build/Update Report"}
                style={{ cursor: "pointer" }}
                src={AddToFindingsIcon}
                onClick={() => addToFindingsClick(record, false, index)}
              />
              <span style={{ color: "#2F6F7B" }}> Add to Report</span>
            </Space>
          )}
        </Space>
      ),
    },
  ];

  //rank order table view
  const rankOrderColumns = [
    {
      title: "Respondents",
      dataIndex: "respondents",
      key: "respondents",
      width: "8%",
      render: (_, { respondents }) => (
        <Tag
          color={"blue"}
          key={respondents}
          style={{
            width: respondents.length > 18 && "230px",
            hyphens: "auto",
            whiteSpace: respondents.length > 25 && "normal",
          }}>
          {respondents}
        </Tag>
      ),
    },
    {
      title: (
        <>
          <Row >
            <Col 
            // flex="186px" 
            flex='25%'
            style={{marginRight:'1%' , marginTop:'auto',marginBottom:'auto'}}>Options</Col>
            <Col style={{ maxWidth: "72%", width: "72%" }}>
              {" "}
              <div style={{ marginBottom: 24 , display:'flex' , justifyContent:'center' }}>Responses</div>
              <div class="horizontal-line-rank">
                <div class="break">
                  <span class="percentage">0</span>
                </div>
                <div class="break">
                  <span class="percentage">25%</span>
                </div>
                <div class="break">
                  <span class="percentage">50%</span>
                </div>
                <div class="break">
                  <span class="percentage">75%</span>
                </div>
                <div class="break">
                  <span class="percentage">100%</span>
                </div>
              </div>
            </Col>
            {/* <Col>Build/Update Report</Col> */}
          </Row>
        </>
      ),
      dataIndex: "responseDetails",
      key: "responseDetails",
      width: "80%",
      render: (a, record, index) => {
        return(
        <div >
          {record.responseDetails.map((item,index2) => {
            const totalPercentage = item.option_value.series.reduce(
              (acc, seriesItem) =>
                acc + seriesItem.data.reduce((sum, val) => sum + val, 0),
              0
            );

            const maxWidth = 100;
            const chartWidth =
              Math.min((totalPercentage / 100) * maxWidth, maxWidth) + "%";
            return (
              <Row style={{ display:'flex' ,paddingBottom:'5px' }}>
                <Col
                  // flex="186px"
                  flex="25%"
                  style={{ marginRight:'1%', display: "flex", alignItems: "center"}}>
                  {item.option_name}
                </Col>
                {/* <Col style={{ width: "57%", maxWidth: "57%" }}> */}
                <Col style={{ width: "73%", maxWidth: "73%" , alignContent:'center'  }}>

                  {/* {console.log("item.option_value", item.option_value)} */}
                  <div style={{ width: chartWidth, maxWidth: chartWidth }}>
                    {" "}
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={item.option_value}
                    />
                  </div>
                </Col>
                
              </Row>
            );
          })}
        </div>
      )},
    },
    {
      title: "Build/Update Report",
      dataIndex: "add",
      key: "add",
      width: "1%",
      render: (_, record, index) => {
        return(
        <Space size="middle">
          {!record?.responseDetails[0].addToFindingsFlag ? (
            <Space>
              <img
                alt={"Build/Update Report"}
                src={AddToFindingsDisabledIcon}
              />
              <span style={{ color: "#B2B0B6" }}> Add to Report</span>
            </Space>
          ) : (
            <Space>
              <img
                alt={"Build/Update Report"}
                style={{ cursor: "pointer" }}
                src={AddToFindingsIcon}
                onClick={()=>handleAllCards(index)}
              />
              <span style={{ color: "#2F6F7B" }}> Add to Report</span>
            </Space>
          )}
        </Space>
      )},
    },
  ];

  const handleViewMore = (key) => {
    setExpandedRows((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleViewMoreClick = (respondents, length) => {
    // Store respondents and length in state

    setCurrentRespondents(respondents);
    setResponseDetailsLength(length);

    // Toggle the state for the specific respondents
    setViewMoreOpenResponses((prevData) => ({
      ...prevData,
      [respondents]: prevData[respondents] ? !prevData[respondents] : true,
    }));
  };

  useEffect(() => {    
    if (currentRespondents !== null) {
      const isViewMoreActive = viewMoreOpenResponses[currentRespondents];
      setMaxItemsToShow((prevData) => ({
        ...prevData,
        [currentRespondents]: isViewMoreActive
          ? responseDetailsLength
          : MAX_DETAILS,
      }));

      console.log(maxItemsToShow);
    }
  }, [viewMoreOpenResponses, currentRespondents, responseDetailsLength]);

  //Grid Type table view
  const gridTypeColumns = [
    {
      title: "Respondents",
      dataIndex: "respondents",
      key: "respondents",
      width: "10%",
      render: (_, { respondents }) => (
        <Tag
          color={"blue"}
          key={respondents}
          style={{
            width: respondents.length > 18 && "230px",
            hyphens: "auto",
            whiteSpace: respondents.length > 25 && "normal",
          }}>
          {respondents}
        </Tag>
      ),
    },
    {
      title: (
        <>
          <Row style={{ justifyContent: "space-between" }}>
            <Col flex="196px">Options</Col>
            <Col style={{ maxWidth: "55%", width: "55%" }}>
              {" "}
              <div style={{ marginBottom: 24 }}>Responses</div>
              <div class="horizontal-line-rank">
                <div class="break">
                  <span class="percentage">0</span>
                </div>
                <div class="break">
                  <span class="percentage">25%</span>
                </div>
                <div class="break">
                  <span class="percentage">50%</span>
                </div>
                <div class="break">
                  <span class="percentage">75%</span>
                </div>
                <div class="break">
                  <span class="percentage">100%</span>
                </div>
              </div>
            </Col>
            <Col>Build/Update Report</Col>
          </Row>
        </>
      ),
      dataIndex: "responseDetails",
      key: "responseDetails",
      width: "60%",
      render: (a, record, index) => {
        const isExpanded = expandedRows[record.key];
        const visibleDetails = isExpanded
          ? record.responseDetails
          : record.responseDetails.slice(0, 2);
        // const visibleDetails = isExpanded ? record.responseDetails : record.responseDetails;

        return (
          <>
            {visibleDetails.map((item) => {
              return (
                <Row style={{ justifyContent: "space-between" }}>
                  <Col
                    flex="196px"
                    style={{ display: "flex", alignItems: "center" }}>
                    {item.option_name}
                  </Col>
                  <Col style={{ width: "57%", maxWidth: "57%" }}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={item.option_value}
                    />
                  </Col>
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    {!item.addToFindingsFlag ? (
                      <Space>
                        <img
                          alt={"Build/Update Report"}
                          src={AddToFindingsDisabledIcon}
                        />
                        <span style={{ color: "#B2B0B6" }}> Add to Report</span>
                      </Space>
                    ) : (
                      <Space>
                        <img
                          alt={"Build/Update Report"}
                          style={{ cursor: "pointer" }}
                          src={AddToFindingsIcon}
                          onClick={() => addToFindingsClick(item, false, a)}
                        />
                        <span style={{ color: "#2F6F7B" }}> Add to Report</span>
                      </Space>
                    )}
                  </Col>
                </Row>
              );
            })}
            {record.responseDetails.length > 2 && (
              <Button
                style={{ color: "#27A6A4", fontWeight: 700, marginTop: 10 }}
                type="link"
                onClick={() => handleViewMore(record.key)}>
                {isExpanded ? "View Less" : "View More"}
              </Button>
            )}
          </>
        );
      },
    },
  ];

  // 2nd view Table AGREE/DISAGREE VIEW Columns
  const agreeDisagreeColumns = [
    {
      title: "Respondents",
      dataIndex: "respondents",
      key: "respondents",
      width: "20%",
      render: (_, { respondents }) => (
        <Tag
          color={"blue"}
          key={respondents}
          style={{
            width: respondents.length > 18 && "230px",
            hyphens: "auto",
            whiteSpace: respondents.length > 25 && "normal",
          }}>
          {respondents}
        </Tag>
      ),
    },
    {
      title: "Yes",
      dataIndex: "agree",
      key: "agree",
      render: (text, record) => (
        <Row>
          <Col style={{ width: 50 }}>{text}%</Col>
          <Tooltip
            title={`${record.agreeHoverString} of ${record.n_size} Respondents`}>
            {" "}
            <Col>
              <Progress
                percent={text}
                size={[300, 28]}
                strokeLinecap={"square"}
                lineBorderRadius={50}
                showInfo={false}
                strokeColor={{
                  from: "#027AFF",
                  to: "#027AFF",
                }}
              />
            </Col>{" "}
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "No",
      dataIndex: "disagree",
      key: "disagree",
      render: (text, record) => (
        <Row>
          <Col style={{ width: 50 }}>{text}%</Col>
          <Tooltip
            title={`${record.disagreeHoverString} of ${record.n_size} Respondents`}>
            {" "}
            <Col>
              <Progress
                percent={text}
                size={[300, 28]}
                strokeLinecap={"square"}
                lineBorderRadius={50}
                showInfo={false}
                strokeColor={{
                  from: "#EC7200",
                  to: "#EC7200",
                }}
              />
            </Col>{" "}
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Build/Update Report",
      dataIndex: "add",
      key: "add",
      render: (_, record, index) => (
        <Space size="middle">
          {!record.addToFindingsFlag ? (
            <Space>
              <img
                alt={"Build/Update Report"}
                src={AddToFindingsDisabledIcon}
              />
              <span style={{ color: "#B2B0B6" }}> Add to Report</span>
            </Space>
          ) : (
            <Space>
              <img
                alt={"Build/Update Report"}
                style={{ cursor: "pointer" }}
                src={AddToFindingsIcon}
                onClick={() => addToFindingsClick(record, false, index)}
              />
              <span style={{ color: "#2F6F7B" }}> Add to Report</span>
            </Space>
          )}
        </Space>
      ),
    },
  ];

  //multiSelect OpenText VIEW Columns
  const multiSelectOpenTextColumns = [
    {
      title: "Respondents",
      dataIndex: "respondents",
      key: "respondents",
      width: "30%",
      render: (_, { respondents }) => (
        <Tag
          color={"blue"}
          key={respondents}
          style={{
            width: respondents.length > 18 && "230px",
            hyphens: "auto",
            whiteSpace: respondents.length > 25 && "normal",
          }}>
          {respondents}
        </Tag>
      ),
    },
    {
      title: "Responses",
      dataIndex: "responseDetails",
      key: "responseDetails",
      width: "80%",
      render: (_, record) => (
        <>
          <div
            className={
              responseType === MULTIPLE_SELECT &&
              questionId === QUESTION_ID &&
              maxItemsToShow[record.respondents] > MAX_DETAILS
                ? "responses"
                : null
            }>
            {record.responseDetails.map((item, index) => {
              console.log("record", record);
              return (
                index <
                  (responseType === MULTIPLE_SELECT &&
                  questionId === QUESTION_ID
                    ? maxItemsToShow[record.respondents] || MAX_DETAILS
                    : record.responseDetails.length) && (
                  <Row style={{ marginBottom: 5 }} key={index}>
                    <Col span={12} style={{ paddingRight: 20 }}>
                      <span>{item.response_name}</span>
                    </Col>
                    <Tooltip
                      title={`${item.hover_string} of ${item.n_size} Respondents`}>
                      <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}>
                        <strong style={{ paddingRight: 10, minWidth: "50px" }}>
                          {item.perc_value}%
                        </strong>

                        <Progress
                          percent={item.perc_value}
                          size={[300, 28]}
                          strokeLinecap={"square"}
                          lineBorderRadius={50}
                          showInfo={false}
                          strokeColor={{
                            from: "#2F9BFF",
                            to: "#2F9BFF",
                          }}
                        />
                      </Col>
                    </Tooltip>
                  </Row>
                )
              );
            })}
          </div>
          {responseType === MULTIPLE_SELECT &&
            questionId === QUESTION_ID &&
            record.responseDetails.length > MAX_DETAILS && (
              <Button
                type="link"
                onClick={() =>
                  handleViewMoreClick(
                    record.respondents,
                    record.responseDetails.length
                  )
                }
                style={{ color: "#27A6A4", padding: 0, fontWeight: 700 }}>
                {viewMoreOpenResponses[record.respondents]
                  ? "View Less"
                  : "View More"}
              </Button>
            )}
        </>
      ),
    },
    {
      title: "Build/Update Report",
      dataIndex: "add",
      key: "add",
      width: "10%",
      // addToFindingsFlag: edit_access_flag
      //   ? item.ques_added_to_analysis_flag
      //   : true,
      render: (_, record, index) => (
        <Space size="middle">
          {!record.addToFindingsFlag ? (
            <Space>
              <img
                alt={"Build/Update Report"}
                src={AddToFindingsDisabledIcon}
              />
              <span style={{ color: "#B2B0B6" }}> Add to Report</span>
            </Space>
          ) : (
            <Space>
              <img
                alt={"Build/Update Report"}
                style={{ cursor: "pointer" }}
                src={AddToFindingsIcon}
                onClick={() => addToFindingsClick(record, false, index)}
              />
              <span style={{ color: "#2F6F7B" }}> Add to Report</span>
            </Space>
          )}
        </Space>
      ),
    },
  ];

  // <----- Sorting & Filter in columns STARTS ----->

  const abc = (event) => {};

  //reason table for Likert Scale
  const reasonColumns1 = [
    {
      key: 1,
      dataIndex: "reason",
      title: "Reason",
      width: "40%",
      sorter: (event) => abc(event),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
        <ReasonFilterDropdown
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterSearch: true,
      ellipsis: true,
      // ...getColumnSearchProps("reason"),
    },
    {
      key: 2,
      dataIndex: "respondents",
      title: "Respondents",
      sorter: (event) => abc(event),
      filters: respondentsDropdownList ? [...respondentsDropdownList] : [],
      filterSearch: true,
      render: (_, { respondents }) => (
        <Tag
          color={"blue"}
          key={respondents}
          style={{
            // width: respondents.length > 18 && "230px",
            width:'auto',
            hyphens: "auto",
            whiteSpace: respondents.length > 25 && "normal",
            wordWrap: 'break-word', wordBreak: 'break-word'
          }}>
          {respondents}
        </Tag>
      ),
    },
    {
      key: 3,
      dataIndex: "preference",
      title: "Preference",
      sorter: (event) => abc(event),
      filters: preferenceDropdownList ? [...preferenceDropdownList] : [],
      filterDropdownStyle: { width: 200 }, // Set the desired width
      filterSearch: true,
      // ...getColumnSearchProps("preference"),
    },
    {
      title: "Build/Update Report",
      dataIndex: "add",
      key: 4,
      width: "15%",
      render: (_, record, index) => (
        <Space size="middle">
          {!record.detail_added_to_analysis_flag ? (
            <Space>
              <img
                alt={"Build/Update Report"}
                src={AddToFindingsDisabledIcon}
              />
              <span style={{ color: "#B2B0B6" }}> Add to Report</span>
            </Space>
          ) : (
            <Space style={{ color: "#2F6F7B" }}>
              <img
                alt={"Build/Update Report"}
                style={{ cursor: "pointer" }}
                src={AddToFindingsIcon}
                onClick={() =>
                  addToFindingsClick(
                    record,
                    true,
                    index,
                    record,
                    "both",
                    tableParams
                  )
                }
              />
              <span> Add to Report</span>
            </Space>
          )}
        </Space>
      ),
    },
  ];

  //reason table for Non-Likert Table
  const reasonColumns2 = [
    {
      key: 1,
      dataIndex: "reason",
      title: "Reason",
      width: "50%",
      sorter: (event) => abc(event),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
        <ReasonFilterDropdown
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterSearch: true,
      filterDropdownStyle: { width: 200 }, // Set the desired width
      ellipsis: true,
      // ...getColumnSearchProps("reason"),
    },
    {
      key: 2,
      dataIndex: "respondents",
      title: "Respondents",
      width:'35%',
      sorter: (event) => abc(event),
      filters: respondentsDropdownList ? [...respondentsDropdownList] : [],
      filterSearch: true,
      render: (_, { respondents }) => (
        <Tag
          color={"blue"}
          key={respondents}
          style={{
            // width: respondents.length > 18 && "230px",
            width:'auto',
            hyphens: "auto",
            whiteSpace: respondents.length > 25 && "normal",
            wordWrap: 'break-word', wordBreak: 'break-word'
          }}>
          {respondents}
        </Tag>
      ),
    },
    {
      title: "Build/Update Report",
      dataIndex: "add",
      key: 3,
      width: "15%",

      render: (_, record, index) => (
        <Space size="middle">
          {/* {disableData ? (
            <img alt={"Build/Update Report"} src={AddToFindingsDisabledIcon} />
          ) : (
            <img
              alt={"Build/Update Report"}
              style={{ cursor: "pointer" }}
              src={AddToFindingsIcon}
              onClick={() =>
                addToFindingsClick(record, true, index, record, "single")
              }
            />
          )} */}

          {!record.detail_added_to_analysis_flag ? (
            <Space>
              <img
                alt={"Build/Update Report"}
                src={AddToFindingsDisabledIcon}
              />
              <span style={{ color: "#B2B0B6" }}> Add to Report</span>
            </Space>
          ) : (
            <Space style={{ color: "#2F6F7B" }}>
              <img
                alt={"Build/Update Report"}
                style={{ cursor: "pointer" }}
                src={AddToFindingsIcon}
                onClick={() =>
                  addToFindingsClick(record, true, index, record, "both")
                }
              />
              <span> Add to Report</span>
            </Space>
          )}
        </Space>
      ),
    },
  ];

    //reason table for Non-Likert Table
    const reasonColumns3 = [
      {
        key: 1,
        dataIndex: "reason",
        title: "Reason",
        width: "40%",
        sorter: (event) => abc(event),
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
          <ReasonFilterDropdown
            setSelectedKeys={setSelectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        ),
        filterSearch: true,
        filterDropdownStyle: { width: 200 }, // Set the desired width
        ellipsis: true,
        // ...getColumnSearchProps("reason"),
      },
      {
        key: 2,
        dataIndex: "respondents",
        title: "Respondents",
        sorter: (event) => abc(event),
        filters: respondentsDropdownList ? [...respondentsDropdownList] : [],
        filterSearch: true,
        render: (_, { respondents }) => (
          <Tag
            color={"blue"}
            key={respondents}
            style={{
              // width: respondents.length > 18 && "230px",
              width:'auto',
              hyphens: "auto",
              whiteSpace: respondents.length > 25 && "normal",
              wordWrap: 'break-word', wordBreak: 'break-word'
            }}>
            {respondents}
          </Tag>
        ),
      },
      {
        // change the key to 4 as key 3 is used for preference for other questions.
        key: 4,
        dataIndex: "combined_indication",
        title: "Indication",
        sorter: (event) => abc(event),
        filters: combinedIndicationList ? [...combinedIndicationList] : [],
        filterDropdownStyle: { width: 200 }, // Set the desired width
        filterSearch: true,
        // ...getColumnSearchProps("preference"),
      },
      {
        key: 3,
        dataIndex: "preference",
        title: "Preference",
        sorter: (event) => abc(event),
        filters: preferenceDropdownList ? [...preferenceDropdownList] : [],
        filterDropdownStyle: { width: 200 }, // Set the desired width
        filterSearch: true,
        // ...getColumnSearchProps("preference"),
      },
      {
        title: "Build/Update Report",
        dataIndex: "add",
        key: 5,
        width: "15%",

        render: (_, record, index) => (
          <Space size="middle">
            {!record.detail_added_to_analysis_flag ? (
              <Space>
                <img
                  alt={"Build/Update Report"}
                  src={AddToFindingsDisabledIcon}
                />
                <span style={{ color: "#B2B0B6" }}> Add to Report</span>
              </Space>
            ) : (
              <Space style={{ color: "#2F6F7B" }}>
                <img
                  alt={"Build/Update Report"}
                  style={{ cursor: "pointer" }}
                  src={AddToFindingsIcon}
                  onClick={() =>
                    addToFindingsClick(record, true, index, record, "both")
                  }
                />
                <span> Add to Report</span>
              </Space>
            )}
          </Space>
        ),
      },
    ];

    //Custom filter dropdown for reason column in reason table
    const ReasonFilterDropdown = ({
      setSelectedKeys,
      confirm,
      clearFilters,
    }) => {
      const [list, setList] = useState([]);
      const [loading, setLoading] = useState(false);
      const [searchText, setSearchText] = useState("");
      const [offset, setOffset] = useState(0);
      const [selectedValues, setSelectedValues] = useState([]);
      const limit = 100;
      const [stopReasonDropdownLoader,setStopReasonDropdownLoader] = useState(false);

      const fetchData = async (reset = false) => {
        setLoading(true);
        const data = await getReasonsCustomDropdown(
          "reason",
          searchText,
          reset ? 0 : offset,
          limit
        );
        if(data.length==0){
          setStopReasonDropdownLoader(true);
        }
        else if(data.length!=100){
          setStopReasonDropdownLoader(true);
          setList(reset ? data : [...list, ...data]);
          setOffset(reset ? limit : offset + limit);
        }
        else{
          setList(reset ? data : [...list, ...data]);
          setOffset(reset ? limit : offset + limit);
        }
        setLoading(false);
      };

      useEffect(() => {
        fetchData(true);
      }, [searchText]);

      const handleScroll = (e) => {
        console.log(e.target.scrollTop , e.target.clientHeight , e.target.scrollHeight , loading)
        if (
          e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight -5 &&
          !loading 
          && !stopReasonDropdownLoader
        ) {
          fetchData();
        }
      };

      const handleCheckboxChange = (value) => {
        setSelectedValues((prev) =>
          prev.includes(value)
            ? prev.filter((item) => item !== value)
            : [...prev, value]
        );
        const updatedValues = selectedRef.current.includes(value)
      ? selectedRef.current.filter((item) => item !== value)
      : [...selectedRef.current, value];
      

    selectedRef.current = updatedValues;
    setAppliedReasonValues(updatedValues);
      };

      const handleApply = () => {
        
        const mergedValues = selectedRef.current;

         if(isReset.current){
          setAppliedReasonValues([]);
          setSelectedKeys([]);
         }
         else{
          setSelectedKeys(mergedValues);
          setAppliedReasonValues(mergedValues || []);
         }
        
        confirm();
      };

      const handleReset = () => {
        setSelectedValues([]);
        selectedRef.current = [];
        setSelectedKeys([]);
        setAppliedReasonValues([]);
        clearFilters();
        isReset.current = true;
      };

      return (
        <div style={{ padding: 8, width: 350 }}>
          <div style={{width: 330}}>
            <Input
              placeholder="Search in filters"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ marginBottom: 8 }}
              prefix={<SearchOutlined style={{color:"darkgray"}} />}
            />
            <div
              style={{ maxHeight: 200, overflow: "auto" }}
              onScroll={handleScroll}>
              {loading && offset === 0 ? (
                <Spin />
              ) : (
                <List
                  dataSource={list}
                  renderItem={(item) => (
                    <List.Item title={item.text}>
                      <Checkbox
                        checked={(selectedRef.current?.includes(item.value)
                          // appliedReasonValues?.includes(item.value)
                        )&& (!isReset.current)}
                        onChange={() => handleCheckboxChange(item.value)}>
                        {item.text}
                      </Checkbox>
                    </List.Item>
                  )}
                />
              )}
              {loading && offset > 0 && <Spin />}
            </div>
            <div style={{ marginTop: 8, textAlign: "right" }}>
              <Button
              type="cc"
                onClick={handleReset}
                style={{ marginRight: 8, float: "left", borderColor: "white" }}
                className={`reset-btn ${selectedValues.length > 0 || appliedReasonValues?.length > 0 ? "enabled" : ""}`}
                // disabled={selectedValues.length === 0 && appliedReasonValues?.length === 0}
                >
                Reset
              </Button>
              <Button className="custom-btn" type="primary" onClick={handleApply}>
                Ok
              </Button>
            </div>
          </div>
        </div>
      );
    };

  //-------------API Calls ---------------
  let responseTypes = "";
  //API for Response Details
  const getResponseDetailsData = async () => {
    setLoading(true);
    const payload = {
      user_id: userId,
      screen_name: "response_details",
      category_name: categoryName,
      question_id: responseRowRecord.id,
      response_type: responseRowRecord.responseType,
      ques_type: responseRowRecord.ques_type,
      comparator_data:
        comparatorData.length > 0
          ? comparatorData
          : [
              {
                age: null,
                race: null,
                gender: null,
                ethnicity: null,
                geography: null,
                indication: null,
                comparator_nm: "",
                origin_country: null,
                sub_indication: null,
                therapeutic_area: null,
              },
            ],
      analysis_id: localStorage.getItem("analysisId") || "",
      analysis_refresh_date: analysisData?.last_updated || "",
      ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
    };

    getResponseDetails(payload)
      .then((response) => {
        // setLoading(true);
        setLoading(false);

        setResponseType(response.result[0].response_type);
        updatedResponseType.current = response.result[0].response_type;
        setAppliedReasonValues(response?.result[0]?.reason);
        setReasonVisibleFlag(response.result[0].reasons_visible_flag);
        responseTypes = response.result[0].reasons_visible_flag;
        if (response.result[0].response_type === SINGLE_SELECT_A) {
          agreeDisagreeChartMapping(response.result[0]);
        } else if (response.result[0].response_type === LIKERT_CHART) {
          likertScaleChartMapping(response.result[0]);
        } else if (response.result[0].response_type === RANK_ORDER) {
          rankOrderMapping(response.result[0]);
        } else if (
          response.result[0].response_type === MULTIPLE_SELECT ||
          response.result[0].response_type === OPEN_RESPONSE ||
          response.result[0].response_type === SINGLE_SELECT_B
        ) {
          multiSelectOpenTextMapping(response.result[0]);
        } else if (response.result[0].response_type === GRID_TYPE) {
          gridTypeMapping(response.result[0]);
        }
        console.log("tableParams ---- " , tableParams , reasonFilterAppliedData)
        getResponseDetailsReasonAPIData(
          tableParams.pagination.current * LIMIT - LIMIT ,
          reasonFilterAppliedData?.sortOrder,
          reasonFilterAppliedData?.sortColumn,
          reasonFilterAppliedData?.reason,
          reasonFilterAppliedData?.preference,
          reasonFilterAppliedData?.respondents, 
          reasonFilterAppliedData?.indication,
        );
      })
      .catch(() => setLoading(false));
  };

  //API for Reasons table

  //API for Reasons Table Dropdowns - indication,respondents,preference
  const getReasonsDropdown = async (categoryType) => {
    const listArr = [];
    const payload = {
      user_id: userId,
      screen_name: "response_details",
      ques_id: responseRowRecord.id,
      category_name: categoryType,
      ques_type: responseRowRecord.ques_type,
      comparator_data:
        comparatorData.length > 0
          ? comparatorData
          : [
              {
                age: null,
                race: null,
                gender: null,
                ethnicity: null,
                geography: null,
                indication: null,
                comparator_nm: "",
                origin_country: null,
                sub_indication: null,
                therapeutic_area: null,
              },
            ],
    };

    getResponseReasonsDropdown(payload).then((response) => {
      Array.isArray(response?.data) &&
        response?.data.forEach((item, index) => {
          const obj = {
            text: item,
            value: item,
            index: index + 1,
          };
          listArr.push(obj);
        });
      const a = [...listArr];
      if (categoryType == "reason") {
        // setReasonDropdownList(a);
      } else if (categoryType == "respondents") {
        setRespondentsDropdownList(a);
      } else if(categoryType == "combined_indication"){
        setCombinedIndicationList(a);
      }
      else {
        setPreferenceDropdownList(a);
      }
    });
  };

  //reason filter API call for custon reason filter dropdown
  const getReasonsCustomDropdown = async (categoryType, searchText = "", offset = 0, limit = 100) => {
    const payload = {
      user_id: userId,
      screen_name: "response_details",
      ques_id: responseRowRecord.id,
      category_name: categoryType,
      search_string: searchText,
      offset: offset,
      limit: limit,
      ques_type: responseRowRecord.ques_type,
      comparator_data:
        comparatorData.length > 0
          ? comparatorData
          : [
              {
                age: null,
                race: null,
                gender: null,
                ethnicity: null,
                geography: null,
                indication: null,
                comparator_nm: "",
                origin_country: null,
                sub_indication: null,
                therapeutic_area: null,
              },
            ],
    };
  
    const response = await getResponseReasonsDropdown(payload);
    return Array.isArray(response?.data)
      ? response.data.map((item, index) => ({
          text: item,
          value: item,
          index: offset + index + 1,
        }))
      : [];
  };

  const multiSelectOpenTextMapping = (response) => {
    const details = response.criteria_details;
    let list = [];

    details.forEach((item, index) => {
      let obj = {
        key: index + 1,
        respondents: item.criteria_name,
        responseDetails: item.details,

        addToFindingsFlag: disableData,
      };
      list.push(obj);
    });
    allCardsData = list;
    setMultiSelectOpenTextData(list);
  };

  const rankOrderMapping = (response) => {
    const details = response.criteria_details;
    const rankLegends = details[0].details[0].option_value;
    const rankOrderLegends = rankOrderLegendsMapping(rankLegends);
    const colorCodes = rankOrderLegends.map((item) => item.colorCode).reverse();
    let list = [];
    details.forEach((item, index) => {
      let chartsArray = [];
      const abc = item.details.map((i) => {
        const sortedData = i.option_value.sort((a, b) => b.name - a.name);
        let chartOp = { ...RANK_ORDER_CHART_OPTIONS };
        chartOp.series = [...sortedData];
        chartOp.tooltip = {
          formatter: function () {
            const hoverString = this.point.series.userOptions.hover_string;
            const nSize = this.point.series.userOptions.n_size;

            return `${hoverString} of ${nSize} Respondents`;
          },
        };
        chartOp.plotOptions.series.dataLabels.formatter = function () {
          if (!Number.isInteger(this.y)) {
            return this.y.toFixed(1) + "%";
          } else {
            return this.y + "%";
          }
        };
        chartOp.colors = [...colorCodes];
        const obj = {
          option_value: chartOp,
          respondents: item.criteria_name,
          option_name: i.option_name,
          addToFindingsFlag: disableData,
          order: i.order,
        };
        chartsArray.push({ ...obj });
      });
      const rankOrderDetails = chartsArray.sort((a, b) => a.order - b.order);
      let obj = {
        key: index + 1,
        respondents: item.criteria_name,
        responseDetails: rankOrderDetails,
      };
      list.push(obj);
    });
    allCardsData = list;
    setRankOrderDataSource(list);
  };

  const rankOrderLegendsMapping = (optionValue) => {
    const rankLength = optionValue.length;
    let rankLegends = [];
    if (rankLength === 3) {
      rankLegends = [...COLOR_PALETTE_THREE_RANK];
    } else if (rankLength === 4) {
      rankLegends = [...COLOR_PALETTE_FOUR_RANK];
    } else if (rankLength === 5) {
      rankLegends = [...COLOR_PALETTE_FIVE_RANK];
    } else if (rankLength === 6) {
      rankLegends = [...COLOR_PALETTE_SIX_RANK];
    } else if (rankLength === 7) {
      rankLegends = [...COLOR_PALETTE_SEVEN_RANK];
    }
    setLikertsLegends(rankLegends);
    return rankLegends;
  };

  const gridTypeMapping = (response) => {
    const details = response.criteria_details;
    const gridTypeLegends = details[0].details[0].option_value;
    const gridLegends = gridTypeLegendsMapping(gridTypeLegends);
    const colorCodes = gridLegends.reverse();
    let list = [];
    details.forEach((item, index) => {
      let chartsArray = [];
      const abc = item.details.map((i) => {
        const sortedData = i.option_value.reverse();
        let chartOp = { ...GRID_TYPE_CHART_OPTIONS };
        chartOp.series = [...sortedData];
        chartOp.tooltip = {
          formatter: function () {
            const hoverString = this.point.series.userOptions.hover_string;
            const nSize = this.point.series.userOptions.n_size;

            return `${hoverString} of ${nSize} Respondents`;
          },
        };
        chartOp.plotOptions.series.dataLabels.formatter = function () {
          if (!Number.isInteger(this.y)) {
            return this.y.toFixed(1) + "%";
          } else {
            return this.y + "%";
          }
        };
        chartOp.colors = [...colorCodes];
        const obj = {
          option_value: chartOp,
          respondents: item.criteria_name,
          option_name: i.option_name,
          addToFindingsFlag: disableData,
          order: i.order,
        };
        chartsArray.push({ ...obj });
      });
      const gridTypeDetails = chartsArray.sort((a, b) => a.order - b.order);
      let obj = {
        key: index + 1,
        respondents: item.criteria_name,
        responseDetails: gridTypeDetails,
      };
      list.push(obj);
    });
    allCardsData = list;
    setGridTypeDataSource(list);
  };

  const gridTypeLegendsMapping = (optionValue) => {
    const rankLength = optionValue.length;
    let gridTypeLegends = [];
    if (rankLength === 3) {
      gridTypeLegends = [...GRID_TYPE_COLOR_PALETTE_THREE];
    } else if (rankLength === 4) {
      gridTypeLegends = [...GRID_TYPE_COLOR_PALETTE_FOUR];
    } else if (rankLength === 7) {
      gridTypeLegends = [...GRID_TYPE_COLOR_PALETTE_SEVEN];
    } else if (rankLength === 5) {
      gridTypeLegends = [...GRID_TYPE_COLOR_PALETTE_FIVE];
    } else if (rankLength === 6) {
      gridTypeLegends = [...GRID_TYPE_COLOR_PALETTE_SIX];
    }
    const result = optionValue.map((item, index) => ({
      name: item.name,
      colorCode: gridTypeLegends[index],
    }));

    setLikertsLegends(result);
    return gridTypeLegends;
  };

  const likertScaleChartMapping = (response) => {
    const details = response.criteria_details;
    const legends = responseRowRecord[details[0]?.criteria_name];

    const reversedDetails = details[0].details.slice();
    
    let colorCodesArray = [];
   
    if(reversedDetails.length<=5){
      colorCodesArray = LIKERT_COLOR_PALETTE_WITH_FIVE_COLORS;
    }
    else if(reversedDetails.length==6){
      colorCodesArray = LIKERT_COLOR_PALETTE_WITH_SIX_COLORS;
    }
    else if(reversedDetails.length == 7){
      colorCodesArray = LIKERT_COLOR_PALETTE
    }
    else if(reversedDetails.length==8){
      colorCodesArray = LIKERT_COLOR_PALETTE_WITH_EIGHT_COLORS;
    }
    else if(reversedDetails.length==9){
      colorCodesArray = LIKERT_COLOR_PALETTE_WITH_NINE_COLORS;
    }
    
    const result = reversedDetails.map((item, index) => ({
      name: item.name,
      colorCode: colorCodesArray[index]
    }));

    setLegendHeads(legends.map((item) => item.response));
    let list = [];
    details.forEach((item, index) => {
      const sortedData = item.details
        .sort((a, b) => b.order - a.order)
        .reverse();
      let chartOp = { ...LIKERT_SCALE_CHART_OPTIONS };
      chartOp.series = [...sortedData];
      const chartColorCodesArray = [...colorCodesArray].reverse();

      chartOp.colors = chartColorCodesArray;
      chartOp.tooltip = {
        formatter: function () {
          const hoverString = this.point.series.userOptions.hover_string;
          const nSize = this.point.series.userOptions.n_size;

          return `${hoverString} of ${nSize} Respondents`;
        },
      };
      chartOp.plotOptions.series.dataLabels.formatter = function () {
        if (!Number.isInteger(this.y)) {
          return this.y.toFixed(1) + "%";
        } else {
          return this.y + "%";
        }
      };
      const obj = {
        key: index + 1,
        respondents: item.criteria_name,
        responses: chartOp,
        addToFindingsFlag: disableData,
      };
      list.push(obj);
    });
    
    setLikertScaleDataSource(list);
    allCardsData = list;

    setLikertsLegends(result);
  };

  const agreeDisagreeChartMapping = (response) => {
    const details = response.criteria_details;
    let list = [];
    details[0].details.sort((a, b) =>
      a.response_name < b.response_name ? 1 : -1
    );
    details.forEach((item, index) => {
      const obj = {
        key: index + 1,
        respondents: item.criteria_name,
        agreeHoverString: item.details[0].hover_string,
        disagreeHoverString: item.details[1].hover_string,
        n_size: item.details[0].n_size,
        agree: item.details[0].perc_value,
        disagree: item.details[1]?.perc_value ?? "",
        addToFindingsFlag: disableData,
      };
      list.push(obj);
    });
    allCardsData = list;
    setAgreeDisagreeDataSource(list);
  };

  const addSupportingData = async (
    data,
    preference,
    reason,
    combined_indication,
    suppCardOrder,
    type
  ) => {
    let analysis_id = localStorage.getItem("analysisId");

    const payload = {
      user_id: userId,
      analysis_id: analysis_id,
      save_type: "supp_ques",
      order_data: {},
      prev_insight_id: "",
      insight_id: "",
      edit_insight_flag: false,
      insight_text: "",
      ques_id: data.ques_id,
      insight_order: 2,
      ques_order: 1,
      insight_ques_order: 0,
      supporting_data: JSON.stringify(data),
      preference,
      reason,
      combined_indication,
      supp_card_id: "",
      supp_card_order: +suppCardOrder + 1,
      added_from: type,
    };
    let response = await saveFindingsReportData(payload);
    return response;
  };
  const [openModal, setIsOpen] = useState(false);
  const [supportingData, setSupportingData] = useState();
  const [type, setType] = useState();
  const addToFindingsClick = (
    record,
    flag,
    i,
    key = undefined,
    tableParams,
    pageNo
  ) => {
    // setLoading(true);
    let makeRankTable = false;
    let optionsNameForRanks=[];
    let rankDetails = [];
    
    let suppCardOrder = localStorage.getItem("suppCardOrder");
    localStorage.setItem("suppCardOrder", parseInt(suppCardOrder)+1); 
    let result;
    // let criteriaDetails = {};
    if (flag) {
      result = {
        ques_id: responseRowRecord.id,
        supp_card_id: "",
        supp_card_order: 1,
        responseType: "Preference",
        ques_text: responseRowRecord.question,
        reason: record?.reason || "",
        respondents: record?.respondents || null,
        preference: record?.preference || "",
        combined_indication: record?.combined_indication || "",
        criteria_details: [],
        category_name: responseRowRecord?.categoryName,
        panel_key: responseRowRecord?.panelKey
          ? responseRowRecord?.panelKey
          : "1",
      };
    } else {
      let objArr = [];
      if (updatedResponseType.current === LIKERT_CHART) {
        const res = record?.responses.series;
        // res.reverse()
        res.forEach((item) => {
          const name = item.name;
          const value = item.data[0];
          const nSize = item.n_size;
          const hover_string = item.hover_string;
          objArr.push({ name, value, nSize, hover_string });
        });
        objArr.reverse();
      } else if (updatedResponseType.current === SINGLE_SELECT_A) {
        objArr.push(
          {
            name: "Yes",
            value: record?.agree,
            hover_string: record?.agreeHoverString,
            nSize: record?.n_size,
          },
          {
            name: "No",
            value: record?.disagree,
            hover_string: record?.disagreeHoverString,
            nSize: record?.n_size,
          }
        );
      } else if (
        updatedResponseType.current === MULTIPLE_SELECT ||
        updatedResponseType.current === OPEN_RESPONSE ||
        updatedResponseType.current === SINGLE_SELECT_B
      ) {
        let maximumDetails = record?.responseDetails.length;
        if(questionId===QUESTION_ID){
          maximumDetails = MAX_DETAILS;
        }
        const res = viewMoreOpenResponses[record.respondents]
          ? record?.responseDetails
          : record?.responseDetails?.slice(0,maximumDetails);
        res.forEach((item) => {
          const name = item.response_name;
          const value = item.perc_value;
          const nSize = item.n_size;
          const hover_string = item.hover_string;
          objArr.push({ name, value, nSize, hover_string });
        });
      } else if (updatedResponseType.current === RANK_ORDER || updatedResponseType.current === GRID_TYPE) {
        let res = []
        if(record?.responseDetails){
          res = record?.responseDetails;
        }
        else{
          res = record?.option_value?.series;
        }
        if(updatedResponseType.current === RANK_ORDER ){
          if(record?.responseDetails){
            res = record?.responseDetails;
            makeRankTable = true;
          }
        }
        if(!makeRankTable){
          res.forEach((item) => {
            const name = item.name;
            const value = item.data[0];
            const nSize = item.n_size;
            const hover_string = item.hover_string;
            objArr.push({ name, value, nSize, hover_string });
          });
          objArr.sort((a, b) => a.name - b.name);
        }
        else{
          for(let i=0;i<res.length;i++){
            objArr=[];
            res[i].option_value?.series.forEach((item) => {
              const name = item.name;
              const value = item.data[0];
              const nSize = item.n_size;
              const hover_string = item.hover_string;
              objArr.push({ name, value, nSize, hover_string });
              
            });
            rankDetails.push(objArr);
            optionsNameForRanks.push(res[i].option_name);
            objArr.sort((a, b) => a.name - b.name);
          }
          objArr=[];
        }
      }

      result = {
        ques_text: responseRowRecord.question,
        ques_id: responseRowRecord.id,
        responseType,
        optionName: record?.option_name,
        criteria_details: [
          { criteria_name: record?.respondents, 
            details: [...objArr],
            optionsNameForRanks: optionsNameForRanks,
            rankDetails:rankDetails,
          },
        ],
        category_name: responseRowRecord?.categoryName,
        panel_key: responseRowRecord?.panelKey
          ? responseRowRecord?.panelKey
          : "1",
      };
    }

    setType(
      result.responseType === "Preference"
        ? "response_reasons"
        : "response_details"
    );
    let analysis_id = localStorage.getItem("analysisId");
      if (analysis_id != null) {

        let type =
          result.responseType === "Preference"
            ? "response_reasons"
            : "response_details";
        
        addSupportingData(
          result,
          result?.preference || "",
          result?.reason || "",
          result?.combined_indication || "",
          suppCardOrder,
          type
        ).then((datas) => {
          handleAddToFindingsClick(record?.order, i, key, pageNo);

          // result.supp_card_order = datas.result.max_supp_card_order + 1;
          // result.supp_card_id = datas.result.supp_card_id;
          if (result.responseType === "Preference") {
            dispatch(
              setFindingsReportAddSupportingData({
                data: {
                  supp_card_id: datas.result.supp_card_id,
                  supp_card_order: +suppCardOrder + 1,
                  supporting_data: {
                    ...result,
                    reason: [result.reason],
                    supp_card_id: datas.result.supp_card_id,
                    supp_card_order: +suppCardOrder + 1,
                    category_name: responseRowRecord?.categoryName,
                    panel_key: responseRowRecord?.panelKey,
                  },
                  ques_order: +suppCardOrder + 1,
                  ques_id: result.ques_id,
                  responseType: "Preference",
                  reason: [result.reason],
                  category_name: responseRowRecord?.categoryName,
                  panel_key: responseRowRecord?.panelKey,
                },
                respondents: result.respondents,
                supp_card_id: datas.result.supp_card_id,
                preference: result.preference,
                reason: [result.reason],
                question: responseRowRecord.question,
                category_name: responseRowRecord?.categoryName,
                panel_key: responseRowRecord?.panelKey,
                combined_indication: result?.combined_indication || "",
                // respondents,
              })
            );
            localStorage.setItem(
              "suppCardOrder",
              datas.result.max_supp_card_order
            );

            return;
          }
          
          dispatch(
            setFindingsReportAddSupportingData({
              data: {
                supp_card_id: datas.result.supp_card_id,
                ques_order: datas.result.max_supp_card_order + 1,
                supp_card_order: +suppCardOrder + 1,
                ques_id: responseRowRecord.ques_id,
                supporting_data: result,
              },
            })
          );
          // localStorage.setItem("suppCardOrder", datas.result.max_supp_card_order);
        });
      } else {
        setIsOpen(true);
        setSupportingData({
          preference: result.preference,
          question: result.ques_text,
          reason: result.reason,
          ques_id: result.ques_id,
          supporting_data: result,
        });
        setLoading(false);

        // handleAddToFindingsClick(i);
      }
  };
  const handleAddToFindingsClick = (record, index, key, pageNo) => {
    // setLoading(true);
    if (key) {
      var _ = undefined;
      setLoading(true);
      console.log("----------", tableParams, reasonFilterAppliedData);
      getResponseDetailsReasonAPIData(
        tableParams.pagination.current * LIMIT - LIMIT,
        reasonFilterAppliedData?.sortOrder,
        reasonFilterAppliedData?.sortColumn,
        reasonFilterAppliedData?.reason,
        reasonFilterAppliedData?.preference,
        reasonFilterAppliedData?.respondents, 
        reasonFilterAppliedData?.indication,

      );

      return;
    }
    if (responseType === SINGLE_SELECT_A) {
      const updatedDataSource = [...agreeDisagreeDataSource];

      updatedDataSource[index].addToFindingsFlag =
        !updatedDataSource[index].addToFindingsFlag;
      setAgreeDisagreeDataSource(updatedDataSource);
    } else if (responseType === LIKERT_CHART) {
      let dataArray = [];
      if (likertScaleDataSource.length > 0) dataArray = dataArray.concat(likertScaleDataSource);
      if (allCardsData.length > 0) dataArray = dataArray.concat(allCardsData);
      const updatedDataSource = [...dataArray];
      updatedDataSource[index].addToFindingsFlag = true;
      setLikertScaleDataSource(updatedDataSource);
    } else if (responseType === RANK_ORDER) {
      
      const updatedDataSource = [...rankOrderDataSource];
      // const indexToUpdate = updatedDataSource[0].responseDetails.findIndex(
      //   (item) => item.order == record
      // );
      // updatedDataSource[0].responseDetails[
      //   indexToUpdate
      // ].addToFindingsFlag = true;
      // setRankOrderDataSource(updatedDataSource);
    } else if (
      responseType === MULTIPLE_SELECT ||
      responseType === OPEN_RESPONSE ||
      responseType === SINGLE_SELECT_B
    ) {

      let dataArray = [];
      if (multiSelectOpenTextData.length > 0) dataArray = dataArray.concat(multiSelectOpenTextData);
      if (allCardsData.length > 0) dataArray = dataArray.concat(allCardsData);
      const updatedDataSource = [...dataArray];

      // const updatedDataSource = [...multiSelectOpenTextData];
      updatedDataSource[index].addToFindingsFlag = true;
      setMultiSelectOpenTextData(updatedDataSource);
    }
  };

  const onClose = () => {
    setOpen(false);
    props.onClose();
    dispatch(setReasonsTableParams({ pagination: { current: 1 } }));
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(() => {    
    if (reasonVisibleFlag === "both") {
      setReasonColumns(reasonColumns1);
    } else if (reasonVisibleFlag === "single") {
      // getReasonsDropdown("reason");
      setReasonColumns(reasonColumns2);
    }else if (reasonVisibleFlag === "triple") {
      setReasonColumns(reasonColumns3);
    }
  }, [
    reasonDropdownList,
    reasonVisibleFlag,
    preferenceDropdownList,
    respondentsDropdownList,
    combinedIndicationList,
    tableParams,
    edit_access_flag,
    deleteCardRefresh,
  ]);
  useEffect(() => {    
    getResponseDetailsData();
  }, [isOpen, edit_access_flag, deleteCardRefresh]);
  const LIMIT = 100;


  const handleTableChange = (pagination, filters, sorter) => {

    dispatch(
      setReasonsTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: pagination.current,
        },
        filters,
        ...sorter,
      })
    );
    // changed 3 and 4 for preference.
    setReasonFilterAppliedData({
      sortOrder: sorter?.order=="descend" ? "DESC" : "ASC",
      sortColumn: sorter?.column?.dataIndex,
      reason: filters["1"],
      respondents :filters["2"],
      indication :filters["4"],
      preference : filters["3"],
    });
    getResponseDetailsReasonAPIData(
      pagination.current * LIMIT - LIMIT,
      sorter?.order == "descend" ? "DESC" : "ASC",
      sorter?.column?.dataIndex,
      filters["1"],
      filters["3"],
      filters["2"],
      filters["4"],
      false
    );

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setLoading(true);
    }

    // setTableParams((prevTableParams) => {
    //   const updatedParams = {
    //     ...prevTableParams,
    //     pagination: {
    //       ...prevTableParams.pagination,
    //       current: pagination.current,
    //     },
    //     filters,
    //     ...sorter,
    //   };
    //   getResponseDetailsReasonAPIData(
    //     updatedParams.pagination.current * 10 - 10,
    //     sorter?.order === "descend" ? "DESC" : "ASC",
    //     sorter?.column?.dataIndex,
    //     filters["1"],
    //     filters["3"],
    //     false
    //   );
    //   if (pagination.pageSize !== prevTableParams.pagination?.pageSize) {
    //     setLoading(true);
    //   }
    //   return updatedParams;
    // });/
  };
  const getResponseDetailsReasonAPIData = useCallback(
    (
      offset = 0,
      sort_order = "ASC",
      sort_column = "reason",
      reason = [],
      preference = [],
      respondents = [],
      combined_indication =[],
      flag = true,
      pageNo = false
    ) => {
      
      // const reasonValue = appliedReasonValues && !isReset.current && reason  ? [...reason , ...appliedReasonValues]  : reason ;
      const reasonValue = reason;
      isReset.current = false;
      const payload = {
        user_id: userId,
        screen_name: "response_details",
        ques_id: responseRowRecord.id,
        reason:   reasonValue || [],
        preference: preference || [],
        respondents: respondents || [],
        combined_indication: combined_indication || [],
        sort_column,
        sort_order,
        comparator_data: comparatorData,
        analysis_id: localStorage.getItem("analysisId") || "",
        analysis_refresh_date: analysisData?.last_updated || "",
        ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
        limit: LIMIT,
        offset: offset,
        ques_type: responseRowRecord.ques_type,
        response_type: responseRowRecord?.responseType, 
      };
      getResponseReasons(payload)
        .then((response) => {
          setLoading(false);

          if (tableParams.pagination.total != response.total_count) {
            dispatch(
              setReasonsTableParams({
                ...tableParams,
                pagination: {
                  ...tableParams.pagination,
                  total: response?.total_count,
                },
              })
            );
          }
          // if (flag) {
          //   setPage(response.total_count);
          //   dispatch(
          //     setReasonsTableParams({
          //       ...tableParams,
          //       pagination: {
          //         ...tableParams.pagination,
          //         total: response?.total_count,
          //       },
          //     })
          //   );
          // }
          if (Object.keys(response?.data).length) {
            let data = response?.data;
            data.forEach((item) => {
              item.detail_added_to_analysis_flag = disableData;
            });

            // Print the modified data
            setReasonDataSource(data);
            if (responseTypes == "both") {
              setReasonColumns(reasonColumns1);
              getReasonsDropdown("preference");
              // getReasonsDropdown("reason");
              getReasonsDropdown("respondents");
            } else if (responseTypes === "single") {
              setReasonColumns(reasonColumns2);
              // getReasonsDropdown("reason");
              getReasonsDropdown("respondents");
            } else if (responseTypes === "triple") {
              setReasonColumns(reasonColumns3);
              // getReasonsDropdown("reason");
              getReasonsDropdown("preference");
              getReasonsDropdown("combined_indication");
              getReasonsDropdown("respondents");
            }
            else {
            }
          } else {
            setReasonDataSource([]);
          }
        })
        .catch(() => {
          dispatch(
            setNotificationData({
              message: "Something went wrong ",
              type: NotificationTypes.ERROR,
            })
          );
        });
    },
    [tableParams, edit_access_flag]
  );

  return (
    <>
      <Spin indicator={antIcon} spinning={loading}>
        <Drawer
          title="View Insight Details"
          placement="right"
          onClose={onClose}
          rootStyle={isOpen ? { marginTop: 57, right: "26.7vw" } : {}}
          open={open}
          width={isOpen ? "63vw" : "70vw"}
          extra={
            <Space>
              <CloseOutlined onClick={onClose} />
            </Space>
          }>
          <div style={{ maxWidth: "80vw" }}>
            <Row>
              <Col span={12}>
                <span>
                  <h3>{categoryName}</h3>
                </span>
                <p>{responseRowRecord.question}</p>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col flex={2}>
                {responseType !== OPEN_RESPONSE && <span>
                  <h5>Response Distribution</h5>
                </span>}
              </Col>
              <Col flex={3} style={{ textAlign: "right" }}>
                {responseType === RANK_ORDER && (
                  <div>
                    <span style={{ paddingRight: 5 }}>
                      Ranking by Preference:
                    </span>

                    {likertsLegends.map((item, index) => {
                      return (
                        <Badge
                          style={{ marginLeft: 5 }}
                          color={item.colorCode}
                          text={item.rank}
                          key={index}
                        />
                      );
                    })}
                  </div>
                )}
              </Col>
            </Row>
          </div>

          <div>
            {responseType === LIKERT_CHART && legendHeads && (
              <React.Fragment>
                <Table
                  dataSource={likertScaleDataSource}
                  columns={likertScaleColumns}
                  pagination={false}
                />
                <div>
                  <Row
                    style={{
                      padding: "50px",
                      justifyContent: "space-around",
                      columnGap: "12px",
                      display:'flex',
                    }}>
                    <Col>
                      <strong>{legendHeads[0]}</strong>
                      <div style={{ display:'grid' }}>
                        {likertsLegends.slice(0, 2).map((item, index) => {
                          return (
                            <Badge
                              style={{ marginRight: 12 }}
                              color={item.colorCode}
                              text={item.name}
                              key={index}
                            />
                          );
                        })}
                      </div>
                    </Col>
                    <Col>
                      <strong>{legendHeads[1]}</strong>
                      <div style={{ display:'grid' }}>
                        {likertsLegends.length > 6
                          ? likertsLegends.slice(2, 5).map((item, index) => {
                              return (
                                <Badge
                                  style={{ marginRight: 12 }}
                                  color={item.colorCode}
                                  text={item.name}
                                  key={index}
                                />
                              );
                            })
                          : likertsLegends.slice(2, 3).map((item, index) => {
                              return (
                                <Badge
                                  style={{ marginRight: 12 }}
                                  color={item.colorCode}
                                  text={item.name}
                                  key={index}
                                />
                              );
                            })}
                      </div>
                    </Col>
                    <Col>
                      <strong>{legendHeads[2]}</strong>
                      <div style={{ display:'grid' }}>
                        {likertsLegends.length > 6
                          ? likertsLegends.slice(5, 7).map((item, index) => {
                              return (
                                <Badge
                                  style={{ marginRight: 12 }}
                                  color={item.colorCode}
                                  text={item.name}
                                  key={index}
                                />
                              );
                            })
                          : likertsLegends.slice(3, 5).map((item, index) => {
                              return (
                                <Badge
                                  style={{ marginRight: 12 }}
                                  color={item.colorCode}
                                  text={item.name}
                                  key={index}
                                />
                              );
                            })}
                      </div>
                    </Col>
                    <Col style={{ display:`${likertsLegends.length<=5?'none':''}`  }}>
                      <strong style={{visibility:'hidden'}} >&nbsp;</strong>
                      <div style={{ display:'grid' }}>
                        {likertsLegends.length > 6
                          ? likertsLegends.slice(7).map((item, index) => {
                              return (
                                <Badge
                                  style={{ marginRight: 22 }}
                                  color={item.colorCode}
                                  text={item.name}
                                  key={index}
                                />
                              );
                            })
                          : likertsLegends.slice(5).map((item, index) => {
                              return (
                                <Badge
                                style={{ marginRight: 22 }}
                                color={item.colorCode}
                                text={item.name}
                                key={index}
                              />
                              );
                            })}
                      </div>
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
            )}
            {responseType === GRID_TYPE && (
              <React.Fragment>
                <Table
                  dataSource={gridTypeDataSource}
                  columns={gridTypeColumns}
                  pagination={false}
                />
                <div>
                  <Row
                    style={{
                      padding: "50px",
                      justifyContent: "space-around",
                      columnGap: "12px",
                    }}>
                    {(likertsLegends.length === 3 ||
                      likertsLegends.length === 5 ||
                      likertsLegends.length === 6) && (
                      <Col>
                        {likertsLegends.map((item, index) => {
                          return (
                            <Badge
                              color={item.colorCode}
                              text={item.name}
                              key={index}
                              style={{ paddingRight: 20 }}
                            />
                          );
                        })}
                      </Col>
                    )}
                    {likertsLegends.length === 4 && (
                      <>
                        <Col style={{ width: "46%" }}>
                          {likertsLegends.slice(0, 2).map((item, index) => {
                            return (
                              <Badge
                                color={item.colorCode}
                                text={item.name}
                                key={index}
                              />
                            );
                          })}
                        </Col>
                        <Col style={{ width: "46%" }}>
                          {likertsLegends.slice(2, 4).map((item, index) => {
                            return (
                              <Badge
                                color={item.colorCode}
                                text={item.name}
                                key={index}
                              />
                            );
                          })}
                        </Col>
                      </>
                    )}
                    {likertsLegends.length === 7 && (
                      <div>
                        <Row>
                          <Col style={{ width: "33%" }}>
                            <div>
                              <strong>{likertsLegends[1]?.name}</strong>
                            </div>
                            {likertsLegends.slice(0, 2).map((item, index) => {
                              return (
                                <Badge
                                  style={{ marginRight: 12 }}
                                  color={item.colorCode}
                                  text={item.name}
                                  key={index}
                                />
                              );
                            })}
                          </Col>
                          <Col style={{ width: "33%" }}>
                            <div>
                              <strong>{likertsLegends[3]?.name}</strong>
                            </div>
                            {likertsLegends.slice(2, 5).map((item, index) => {
                              return (
                                <Badge
                                  style={{ marginRight: 12 }}
                                  color={item.colorCode}
                                  text={item.name}
                                  key={index}
                                />
                              );
                            })}
                          </Col>
                          <Col style={{ width: "33%" }}>
                            <div>
                              <strong>{likertsLegends[5]?.name}</strong>
                            </div>
                            {likertsLegends.slice(5, 7).map((item, index) => {
                              return (
                                <Badge
                                  style={{ marginRight: 12 }}
                                  color={item.colorCode}
                                  text={item.name}
                                  key={index}
                                />
                              );
                            })}
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Row>
                </div>
              </React.Fragment>
            )}
            {responseType === RANK_ORDER && (
              <Table
                dataSource={rankOrderDataSource}
                columns={rankOrderColumns}
                pagination={false}
              />
            )}
            {responseType === SINGLE_SELECT_A && (
              <Table
                dataSource={agreeDisagreeDataSource}
                columns={agreeDisagreeColumns}
                pagination={false}
              />
            )}
            {(responseType === MULTIPLE_SELECT ||
              responseType === SINGLE_SELECT_B ) && (
              <Table
                dataSource={multiSelectOpenTextData}
                columns={multiSelectOpenTextColumns}
                pagination={false}
              />
            )}
            {reasonDataSource &&
              (reasonVisibleFlag === "both" ||
                reasonVisibleFlag === "single" ||
                reasonVisibleFlag === "triple") && (
                <div
                  style={{
                    border: "#DEDCDE solid 1px",
                    padding: 10,
                    marginTop: 10,
                  }}>
                  <span>
                    <h5>Reasons Provided for Answers</h5>
                  </span>
                  <Table
                    dataSource={reasonDataSource}
                    columns={reasonColumns}
                    showSorterTooltip={false}
                    pagination={{
                      ...tableParams.pagination,
                      pageSize:100, // for showing 100 records per page
                      showSizeChanger: false, // Set showSizeChanger to false
                    }}
                    loading={loading}
                    // pagination={false}
                    onChange={handleTableChange}
                  />
                </div>
              )}
          </div>
          {openModal && (
            <FindingReportSettingsModal
              isOpen={openModal}
              setIsOpen={setIsOpen}
              supporting_data={supportingData}
              type={type}
              // filterData={filterData}
              // recordData={recordData}
            />
          )}
        </Drawer>
      </Spin>
    </>
  );
});
export default ResponseDetails;
